<template>
  <div>
    <div>
      <h3><b>Users</b></h3>
      <div class="candidates-listing-search-wrapper" style="margin-bottom: 2%">
        <div class="candidates-listing-search-box-wrapper">
          <div class="candidates-listing-header-search-wrapper">
            <a-input-search
              class="candidates-listing-header-search-box"
              size="large"
              placeholder="Search"
            />
          </div>
        </div>
      </div>
    </div>
    <div class='candidate-total-with-add-button'>
      <div class="candidates-listing-users-listings-results">
        <span class="candidates-listing-count" style="opacity: 0.5; font-family: 'PoppinsSemiBold';">{{this.count}} Users</span>
      </div>
      <div>
        <a-button @click="createUerForm()" type="danger" shape="round" :size="size">
          <template #icon>
            <PlusOutlined />
            Create User
          </template>
        </a-button>
      </div>
    </div>
  </div>
  <a-table
    :row-selection="rowSelection"
    :columns="this.teamColumn"
    :data-source="candidatesData"
    :pagination="false"
  >
    <template #icon="{ text }"
      ><img v-if="text" src="{{text}}" alt="Candidate image" />
      <img v-else src="../../../assets/candidate.png" alt="..." />
    </template>
    <template #username="{ text }"
    >
      <a @click="directRouteProfile(text.id)" style="color:black">{{ text.name ? text.name : '-' }}</a>
    </template>
    <template #site="{ text }">
        {{ text ? text : '-' }}
    </template>
    <template #status="{ text }">
      <a-switch v-if="text=='ACTIVE'"  v-model:checked=checked />
      <a-switch v-else v-model:checked='unchecked' />
    </template>
    <template #team="{ text }">
      <div v-for="(team_data, index) in text" :key="index">
        <a-tag v-if="text[0].name">{{team_data.name }}</a-tag>
        <p v-else>-</p>
      </div>
    </template>
    <template #subteam="{ text }">
       <div v-for="(subTeam_data, index) in text" :key="index">
        <a-tag v-if="text[0].name">{{subTeam_data.name }}</a-tag>
        <p v-else>-</p>
      </div>
    </template>
  </a-table>
  <div class="view-more-btn" v-if="this.count !== this.candidatesData.length && this.count > 11">
      <a-button type="none" shape="round" :size="size" class="borderless-btn"
      @click="viewMorePromocodes">
          <template #icon>
            <PlusCircleOutlined />
            View More
          </template>
    </a-button>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import Services from "@/services/apis";
import { PlusOutlined,PlusCircleOutlined } from "@ant-design/icons-vue";
const data = [];

export default defineComponent({
  components: {
    PlusOutlined,
    PlusCircleOutlined
  },
  setup() {
    // const checked = ref(false);
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
      },
      getCheckboxProps: (record) => ({
        // disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        username: record.name,
      }),
    };
    return {
      rowSelection,
    };
  },

  data() {
    return {
      data: data,
      candidatesData: [],
      //   rowSelection,
      //   rowSelection,
      teamColumn: [],
      coachData: [],
      coachListing: [],
      organisationsListing: [],
      organisationData: [],
      corporateListing: [],
      openInvitePlayerModal: false,
      currentPageNum: 0,
      search: "",
      openFilter: false,
      selectedSport: [],
      title: "",
      paginationValues: {},
      checked: true,
      allBookingCount: 0,
      scrollIncrement: 1,
      count:0
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.bindDataAndColumns();
    this.getCandidateDetails();

    const listElm = document.querySelector(".ant-table-body");

    listElm.addEventListener("scroll", async (e) => {
      if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
        this.scrollIncrement++;
        this.getCandidateDetails();
      }
    });
  },
  methods: {
    viewMorePromocodes() {
      this.currentPageNum++;
      this.getCandidateDetails();
    },
    bindDataAndColumns() {
      this.teamColumn = [
        {
          title: "",
          dataIndex: "icon",
          width: 30,
          slots: { customRender: "icon" },
          colSpan: 0,
          alt: "User image",
        },
        {
          title: "User Name",
          dataIndex: "username",
          colSpan: 2,
          slots: {
            customRender: "username",
          },
        },
        {
          title: "Site",
          dataIndex: "site",
          slots: {
            customRender: "site",
          },
        },
        {
          title: "Team",
          dataIndex: "team",
          slots: {
            customRender: "team",
          },
        },
        {
          title: "Sub Team",
          dataIndex: "subteam",
          slots: {
            customRender: "subteam",
          },
        },
        {
          title: "Status",
          dataIndex: "status",
          slots: { customRender: "status" },
        },
      ];
      console.log("colum", this.teamColumn);
    },
    getCandidateDetails() {
      let payload = {
        q: "",
        pageNo: this.currentPageNum,
        mode: "LISTING",
        type: "MEMBER"
      };
      Services.userListing(payload)
        .then((response) => {
          const { data } = response;
          console.log("response", response);
          data.data.map((x) => {
            this.candidatesData.push({
              username: {'name':x.firstName,'id':x.id},
              site: x.site,
              team: x.teams.length!=0 ? x.teams : '-',
              subteam: x.subTeams.length!=0 ? x.subTeams : '-',
              status: x.status,
              id:x.id
            });
            this.count = response.data.count;
            console.log('count',this.candidatesData)
            this.loading = false;
          });
          console.log('gargaergu',this.candidatesData[0].subteam)
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    directRouteProfile(id) {
      this.$router.push(`/team-member-profile/${id}`);
    },
    createUerForm() {
      this.$router.push(`/add-team-member`);
    }
  },
});
</script>
<style scoped>
@import "../styles/teamListing.scss";
</style>