<template>
  <div class="preference-main">
    <!-- Preference search -->
    <div class="preference-main-search">
         <a-input-search
            v-model:value="value"
            placeholder="Search"
            @search="onSearch"
            class="preferences-search"
        />
    </div>
    <div class="preference-component-wrapper">
            <!-- Preference Level Component -->
            <div class="preference-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                            <th class="th" style="width:70%;padding:0px 10px">
                                <div class="preference-header-search">
                                 <span class="th-span">Level Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:20%;text-align:center">
                                Active
                            </th>
                            <th class="th" style="width:10%;padding:0px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="level in levelData" :key="level.id"> 
                                <td class="td" style="text-align:left;padding:0px 10px">
                                {{level ? level.name :'-'}}
                                </td>
                                 <td class="td" style="text-align:center">
                                 <a-checkbox value="" :checked="level.status==='ACTIVE'?true:false" class="master-active-checkbox">
                                  </a-checkbox>
                                </td>
                                 <td class="td" style="text-align:right;padding:0px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>

            <!-- Preference Function Group Component -->
            <div class="master-main-wrapper">
                  <div class="preference-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                            <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                            <th class="th" style="width:85%;padding:12px 10px">
                                <div class="preference-header-search">
                                 <span class="th-span">Function Group Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:10%;padding:0px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="functionGroup in functionGroupData" :key="functionGroup.id"> 
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:12px 10px">
                                <span>{{ functionGroup.name ? functionGroup.name : '-' }}</span>
                                </td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>
            </div>

            <!-- Preference Function Component -->
            <div class="master-main-wrapper">
                 <div class="preference-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                             <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                            <th class="th" style="width:20%;padding:0px 10px">
                                 <div class="preference-header-search">
                                  <span class="th-span">Function Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:45%;text-align:left">
                              <div class="preference-header-search">
                                 <span class="th-span">Function Group</span>
                                  <img src="../../../../../assets/filtericon.png"  alt="" class="img-filter"/>
                                </div>
                            </th>
                            <th class="th" style="width:20%;text-align:center">
                                Active
                            </th>
                            <th class="th" style="width:10%;padding:0px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="functions in functionData" :key="functions.id"> 
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:0px 10px">
                                  <span>{{functions.name}}</span>
                                </td>
                                 <td class="td" style="text-align:left">
                                 -
                                </td>
                                
                                 <td class="td" style="text-align:center;padding:0px 10px">
                                 <a-checkbox value="" :checked="functions.status==='ACTIVE'?true:false" class="master-active-checkbox">
                                  </a-checkbox>
                                </td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>
            </div>

            <!-- Preference Sub Function Component -->
            <div class="master-main-wrapper">
                <div class="preference-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                           <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                            <th class="th" style="width:65%;padding:0px 10px">
                                 <div class="preference-header-search">
                                  <span class="th-span">Sub Function Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:20%;text-align:center">
                                Active
                            </th>
                            <th class="th" style="width:10%;padding:0px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="subFunction in subFunctionData" :key="subFunction.id"> 
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:0px 10px">
                                <span>{{subFunction.name}}</span>
                                </td>
                                 <td class="td" style="text-align:center">
                                <a-checkbox value="" :checked="subFunction.status==='ACTIVE'?true:false" class="master-active-checkbox">
                                  </a-checkbox>
                                </td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>
            </div>

            <!-- Preference  Sub sub Function Component -->
            <div class="master-main-wrapper">
                <div class="preference-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                              <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                            <th class="th" style="width:65%;padding:0px 10px">
                                <div class="preference-header-search">
                                 <span class="th-span">Sub Sub Function Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:20%;text-align:center">
                                Active
                            </th>
                             <th class="th" style="width:10%;padding:0px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="subSubFunction in subSubFunctionData" :key="subSubFunction.id"> 
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:0px 10px">
                                <span> {{subSubFunction.name}}</span>
                                </td>
                                 <td class="td" style="text-align:center">
                                 <a-checkbox value="" :checked="subSubFunction.status==='ACTIVE'?true:false" class="master-active-checkbox">
                                  </a-checkbox>
                                </td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>
            </div>

            <!-- Preference Products Component -->
            <div class="master-main-wrapper">
                <div class="preference-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                             <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked"></a-checkbox>
                            </th>
                            <th class="th" style="width:65%;padding:0px 10px">
                                <div class="preference-header-search">
                                 <span class="th-span">Product Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:20%;text-align:center">
                                Active
                            </th>
                             <th class="th" style="width:10%;padding:0px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="product in productMaster" :key="product.id"> 
                               <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:0px 10px">
                                <span>{{product.name}}</span>
                                </td>
                                 <td class="td" style="text-align:center">
                                 <a-checkbox value="" :checked="product.status==='ACTIVE'?true:false" class="master-active-checkbox">
                                  </a-checkbox>
                                 </td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>
            </div>

             <!-- Preference Practice area Component -->
            <div class="master-main-wrapper">
                <div class="preference-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                             <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                            <th class="th" style="width:30%;padding:12px 10px">
                                <div class="preference-header-search">
                                 <span class="th-span">Vertical Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:20%;padding:12px 10px">
                                <span class="th-span">VA</span>
                            </th>
                            <th class="th" style="width:35%;padding:12px 10px">
                                <span class="th-span">Site</span>
                            </th>
                             <th class="th" style="width:10%;padding:12px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="vertical in verticalMaster" :key="vertical.id"> 
                               <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:0px 10px">
                                <span>{{vertical.name}}</span>
                                </td>
                                <td class="td" style="width:5%;text-align:left;padding:0px 10px">
                                <span>-</span>
                                </td>
                                
                                <td class="td" style="text-align:left;padding:0px 10px">
                                </td>
                                <td class="td" style="text-align:left;padding:0px 10px">
                                </td>
                                  <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>
            </div>

             <!-- Preference Sub Practice area Component -->
            <div class="master-main-wrapper">
                <div class="preference-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                             <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                            <th class="th" style="width:20%;padding:12px 10px">
                                <div class="preference-header-search">
                                 <span class="th-span">Practice Area Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:20%;padding:12px 10px">
                               <div class="preference-header-search">
                                 <span class="th-span">Vertical</span>
                                  <img src="../../../../../assets/filtericon.png"  alt="" class="img-filter"/>
                                </div>
                            </th>
                            <th class="th" style="width:10%;padding:12px 10px">
                                <span class="th-span">ABBR</span>
                            </th>
                            <th class="th" style="width:15%;padding:12px 10px">
                                <span class="th-span">Display Name</span>
                            </th>
                            <th class="th" style="width:10%;text-align:center;padding:12px 10px">
                                Display Website
                            </th>
                            <th class="th" style="width:10%;text-align:center;padding:12px 10px">
                                Active
                            </th>
                             <th class="th" style="width:10%;padding:12px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="practiceArea in practiceAreaMaster" :key="practiceArea.id"> 
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:0px 10px">
                               <span>{{practiceArea.name}}</span>
                                </td>
                                <td class="td" style="text-align:left;padding:0px 10px">
                                <!-- {{practiceArea.description ? practiceArea.description : '-'}} -->
                                -
                                </td>
                                <td class="td" style="text-align:left;padding:0px 10px">
                                <!-- {{practiceArea.abbreviation ? practiceArea.abbreviation : '-'}} -->
                                -
                                </td>
                                <td class="td" style="text-align:left;padding:0px 10px">
                                </td>
                                 <td class="td" style="text-align:center">
                                </td>
                                <td class="td" style="text-align:center">
                                <a-checkbox value="" :checked="practiceArea.status==='ACTIVE'?true:false" class="master-active-checkbox">
                                  </a-checkbox>
                                </td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>
            </div>

             <!-- Preference Segments Component -->
            <div class="master-main-wrapper">
                <div class="preference-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                           <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                           <th class="th" style="width:20%;padding:12px 10px">
                                <div class="preference-header-search">
                                 <span class="th-span">Sub Practice Area Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:20%;padding:12px 10px">
                                <div class="preference-header-search">
                                 <span class="th-span">Practice Area</span>
                                  <img src="../../../../../assets/filtericon.png"  alt="" class="img-filter"/>
                                </div>
                            </th>
                            <th class="th" style="width:10%;padding:12px 10px">
                                <span class="th-span">SPA</span>
                            </th>
                            <th class="th" style="width:15%;padding:12px 10px">
                                <span class="th-span">Display Name</span>
                            </th>
                            <th class="th" style="width:10%;text-align:center;padding:12px 10px">
                                Display Website
                            </th>
                            <th class="th" style="width:10%;text-align:center;padding:12px 10px">
                                Active
                            </th>
                             <th class="th" style="width:10%;padding:12px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="subPracticeArea in subPracticeAreaMaster" :key="subPracticeArea.id"> 
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:0px 10px">
                                <span>{{subPracticeArea.name}}</span>
                                </td>
                                <td class="td" style="text-align:left;padding:0px 10px">
                                </td>
                                <td class="td" style="text-align:left;padding:0px 10px">
                                </td>
                                <td class="td" style="text-align:left;padding:0px 10px">
                                </td>
                                 <td class="td" style="text-align:center">
                                </td>
                                <td class="td" style="text-align:center">
                                <a-checkbox value="" :checked="subPracticeArea.status==='ACTIVE'?true:false" class="master-active-checkbox">
                                  </a-checkbox>
                                </td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>
            </div>

             <!-- Preference Vertical Component -->
            <div class="master-main-wrapper">
                <div class="preference-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                             <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                            <th class="th" style="width:65%;padding:12px 10px;text-align:left">
                                 <div class="preference-header-search">
                                 <span class="th-span">Segment Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:20%;text-align:center">
                                Active
                            </th>
                            <th class="th" style="width:10%;padding:12px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="segment in segmentMaster" :key="segment.id"> 
                                 <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:12px 10px">
                                <span> {{segment.name}}</span>
                                </td>
                                 <td class="td" style="text-align:center;padding:12px 10px">
                                 <a-checkbox value="" :checked="segment.status==='ACTIVE'?true:false" class="master-active-checkbox">
                                  </a-checkbox>
                                </td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>
            </div>
    </div>
  </div>
</template>

<script>
import Services from "@/services/apis";
export default {
  mounted() {
    window.scrollTo(0, 0);
    this.getlevelDetails();
    this.getFunctionGroupDetails();
    this.getFunctionDetails();
    this.getSubFunctionDetails();
    this.getSubSubFunctionDetails();
    this.getProductMasterDetails();
    this.getVerticalMasterDetails();
    this.getPracticeAreaDetails();
    this.getSubPracticeAreaDetails();
    this.getSegmentDetails();
  },  
  methods:{
    getlevelDetails(){
       let payload = {
        q: "",
        pageNo: 0,
        mode: "LISTING",
        types: ["DESIGNATION"]
      };
      Services.getlevelDetail(payload)
        .then((response) => {
           const { data } = response;
          const {data: responsesData = []} =  data[0];
          this.levelData= responsesData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    viewMorePromocodes() {
      this.currentPageNum++;
      this.getFunctionGroupDetails();
      console.log('current page',this.currentPageNum)
    },
    getFunctionGroupDetails() {
      let payload = {
        q: "",
        pageNo: 0,
        mode: "LISTING",
        type: "FUNCTION_GROUPS"
      };
      Services.getFunctionGroupDetail(payload)
        .then((response) => {
          const { data } = response;
          const {data: responsesData = []} =  data[0];
          this.functionGroupData= responsesData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    getFunctionDetails() {
      let payload = {
        q: "",
        pageNo: 1,
        mode: "LISTING",
        types:["FUNCTION"]
      };
      Services.getFunctionDetail(payload)
        .then((response) => {
          const { data } = response;
          const {data: responsesData = []} =  data;
          this.functionData= responsesData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    getSubFunctionDetails() {
        let payload = {
        q: "",
        pageNo: 1,
        mode: "LISTING",
        types:["SUB_FUNCTION"]
      };
      Services.getSubFunctionDetail(payload)
        .then((response) => {
          const { data } = response;
          const {data: responsesData = []} =  data;
          this.subFunctionData= responsesData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    getSubSubFunctionDetails() {
         let payload = {
        q: "",
        pageNo: 1,
        mode: "LISTING",
        types:["SUB_SUB_FUNCTION"]
      };
      Services.getSubSubFunctionDetail(payload)
        .then((response) => {
          const { data } = response;
          const {data: responsesData = []} =  data;
          this.subSubFunctionData= responsesData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    getProductMasterDetails() {
        let payload = {
        q: "",
        pageNo: 1,
        mode: "LISTING",
      };
      Services.getProductMasterDetail(payload)
        .then((response) => {
          const { data } = response;
          
          const {data: responsesData = []} =  data[0];
          
          this.productMaster= responsesData;
          
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    getVerticalMasterDetails() {
        let payload = {
        q: "",
        pageNo: 1,
        mode: "LISTING",
      };
      Services.getVerticalMasterDetail(payload)
        .then((response) => {
          const { data } = response;
          const {data: responsesData = []} =  data;
          this.verticalMaster= responsesData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    getPracticeAreaDetails() {
        let payload = {
        q: "",
        pageNo: 1,
        mode: "LISTING",
        type:"PRACTICEAREA"
      };
      Services.getPracticeAreaDetail(payload)
        .then((response) => {
          const { data } = response;
          console.log('practice area',response)
          const {data: responsesData = []} =  data;
          console.log('practice area DATA',responsesData)
          this.practiceAreaMaster= responsesData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    getSubPracticeAreaDetails () {
        let payload = {
        q: "",
        pageNo: 1,
        mode: "LISTING",
      };
      Services.getSubPracticeAreaDetail(payload)
        .then((response) => {
          const { data } = response;
          const {data: responsesData = []} =  data;
          this.subPracticeAreaMaster= responsesData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    getSegmentDetails () {
        let payload = {
        q: "",
        pageNo: 1,
        mode: "LISTING",
      };
      Services.getSegmentDetail(payload)
        .then((response) => {
          const { data } = response;
          const {data: responsesData = []} =  data;
          this.segmentMaster= responsesData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
  },
  data() {
    return {
        levelData: [],
        functionGroupData: [],
        functionData: [],
        subFunctionData: [],
        subSubFunctionData: [],
        productMaster: [],
        verticalMaster: [],
        practiceAreaMaster: [],
        subPracticeAreaMaster: [],
        segmentMaster: []
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/Preferences.scss";
</style>

