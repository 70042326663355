<template>
  <div class="master-side-navigation">
    <a-tree checkable  :tree-data="treeData" @check="onSelect"  v-model:checkedKeys="checkedKeys"> </a-tree>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
// import ROUTES from './constants/routes';
// import { useRouter } from "vue-router";

//  const router = useRouter();


const treeData = [
  {
    title: "Master",
    key: "MASTER_MAIN",
    disableCheckbox: true,
    children: [
      {
        title: "Global",
        key: "GLOBAL_MAIN",
        children: [
          {
            title: "Gender",
            key: "GLOBAL_GENDER",
          },
          {
            title: "Title ",
            key: "GLOBAL_TITLE",
          },
          {
            title: "Company Types",
            key: "GLOBAL_COMPANY_TYPES",
          },
          {
            title: "Sites",
            key: "GLOBAL_SITES",
          },
          {
            title: "Currency",
            key: "GLOBAL_CURRENCY",
          },
        ],
      },
      {
        title: "Location",
        key: "LOCATION_MAIN",
        children: [
          {
            title: "Country",
            key: "LOCATION_COUNTRY",
          },
          {
            title: "States",
            key: "LOCATION_STATES",
          },
          {
            title: "Cities",
            key: "LOCATION_CITIES",
          },
          {
            title: "Region",
            key: "LOCATION_REGION",
          },
        ],
      },
      {
        title: "Preferences",
        key: "PREFERENCES_MAIN",
        children: [
          {
            title: "Level",
            key: "PREFERENCES_LEVEL",
          },
          {
            title: "Function Group",
            key: "PREFERENCES_GROUP",
          },
          {
            title: "Function",
            key: "PREFERENCES_FUNCTION",
          },
          {
            title: "Sub Function",
            key: "PREFERENCES_SUB_FUNCTION",
          },
          {
            title: "Sub Sub Function",
            key: "PREFERENCES_SUB_SUB_FUNCTION",
          },
          {
            title: "Product",
            key: "PREFERENCES_PRODUCT_FUNCTION",
          },
          {
            title: "Practice Area",
            key: "PREFERENCES_PRACTICE_AREA_FUNCTION",
          },
          {
            title: "Sub Practice",
            key: "PREFERENCES_SUB_PRACTICE_FUNCTION",
          },
          {
            title: "Segment",
            key: "PREFERENCES_SEGMENT_FUNCTION",
          },
          {
            title: "Vertical",
            key: "PREFERENCES_Vertical_FUNCTION",
          },
        ],
      },
      {
        title: "Teams",
        key: "TEAM_MAIN",
        children: [
          {
            title: "Roles",
            key: "TEAM_ROLES",
          },
          {
            title: "Team Designation",
            key: "TEAM_DESIGNATION",
          },
          {
            title: "Team",
            key: "TEAM_TEAM",
          },
          {
            title: "Sub Team",
            key: "TEAM_SUB_TEAM",
          },
        ],
      },
      {
        title: "User",
        key: "USER",
      },
      {
        title: "Security",
        key: "security",
      },

      {
        title: "Resume/Candidate",
        key: "RESUME_CANDIDATE_MAIN",
        children: [
          {
            title: "Resume Type",
            key: "RESUME_CANDIDATE_RESUME_TYPE",
          },
          {
            title: "Education Level",
            key: "RESUME_CANDIDATE_EDUCATION_LEVEL",
          },
          {
            title: "Edu. Degree",
            key: "RESUME_CANDIDATE_EDU_DEGREE",
          },
          {
            title: "Edu. Specialization",
            key: "RESUME_CANDIDATE_EDU_SPECIALIZATION",
          },
          {
            title: "Edu. Institutes",
            key: "RESUME_CANDIDATE_EDU_INSTITUTES",
          },
        ],
      },
      {
        title: "Company",
        key: "COMPANY_MAIN",
        children: [
          {
            title: "Unit Type",
            key: "COMPANY_UNIT_TYPE",
          },
        ],
      },
      {
        title: "Meetings",
        key: "MEETINGS_MAIN",
        children: [
          {
            title: "Meeting Type",
            key: "MEETINGS_TYPE",
          },
        ],
      },
      {
        title: "Documents",
        key: "DOCUMENTS_MAIN",
        children: [
          {
            title: "Document Types",
            key: "DOCUMENTS_TYPE",
          },
        ],
      },
      {
        title: "Vacancy",
        key: "VACANCY_MAIN",
        children: [
          {
            title: "Stage",
            key: "VACANCY_STAGE",
          },
          {
            title: "Vacancy Type",
            key: "VACANCY_VACANCY_LIST",
          },
          {
            title: "Vacancy Status",
            key: "VACANCY_STATUS",
          },
          {
            title: "Department",
            key: "VACANCY_DEPARTMENT",
          },
          {
            title: "Procedure Type",
            key: "VACANCY_PROCEDURE_TYPE",
          },
          {
            title: "Tracker Type",
            key: "VACANCY_TRACKER_TYPE",
          },
          {
            title: "Reminder Type",
            key: "VACANCY_REMINDER_TYPE",
          },
          {
            title: "RM Status",
            key: "VACANCY_RM_STATUS",
          },
          {
            title: "Sector",
            key: "VACANCY_SECTOR",
          },
          {
            title: "Industry",
            key: "VACANCY_INDUSTRY",
          },
        ],
      },
    ],
  },
];
export default {
  setup(props, { emit }) {

   let childData = ref([]); 

    let checkedKeys = ref([]);
    watch(checkedKeys, () => {
      // console.log('checkedKeys Val  :>> ', checkedKeys.value);
      if(checkedKeys.value) {
        let prevOption = checkedKeys.value[0].split("_")[0];
        let newOption = ""; 
        checkedKeys.value.filter(val => {
          if(newOption.length === 0 && !val.includes(prevOption)) {
            newOption = val;
          }
        });

        // console.log('checkedKeys.value ---- :>>', checkedKeys.value);

        let copyValues = [];

        if(newOption) {
          checkedKeys.value.map(val => {
            if(!val.includes(prevOption)) {
              copyValues.push(val);
            }
            // console.log('copyValues :>> ', copyValues);
          });
          // console.log('checkedKeys Val 1:>> ', childData);
          
          childData = checkedKeys.value;
          // console.log('checkedKeys Val 2:>> ', childData);
          checkedKeys.value = copyValues;
          // childData.push(checkedKeys);

        }
      // console.log('checkedKeys 1:>> ' , childData);
        
      }
      // console.log('checkedKeys 2:>> ' , childData);

    });
    const onSelect = (selectedKeys, childData, info) => {
      console.log('checkedKeys 3 :>> ',childData, checkedKeys.value);
      console.log("selectedKeys", selectedKeys , selectedKeys.value, info);


      if (selectedKeys[0].toLowerCase().includes("global")) {
        emit("masterSideNaviagtionProps",  childData , "GLOBAL") ;
      } else if (selectedKeys[0].toLowerCase().includes("location")) {
           emit("masterSideNaviagtionProps", childData , "LOCATION");
      } else if (selectedKeys[0].toLowerCase().includes("preferences")) {
        emit("masterSideNaviagtionProps",childData , "PREFERENCES");
      } else if (selectedKeys[0].toLowerCase().includes("team")) {
        emit("masterSideNaviagtionProps", childData ,"TEAM");
      } else if (selectedKeys[0].toLowerCase().includes("resume")) {
        emit("masterSideNaviagtionProps", childData, "RESUME_CANDIDATE_MAIN");
      } else if (selectedKeys[0].toLowerCase().includes("company")) {
        emit("masterSideNaviagtionProps", childData,"COMPANY");
      } else if (selectedKeys[0].toLowerCase().includes("meeting")) {
        emit("masterSideNaviagtionProps", childData,"MEETINGS");
      } else if (selectedKeys[0].toLowerCase().includes("document")) {
        emit("masterSideNaviagtionProps", childData, "DOCUMENTS");
      } else if (selectedKeys[0].toLowerCase().includes("vacancy")) {
        emit("masterSideNaviagtionProps", childData, "VACANCY");
      } else if (selectedKeys[0].toLowerCase().includes("user")) {
        emit("masterSideNaviagtionProps", childData, "USER");
      }

      //       const USER = {
      //   template: `
      //     <div class="user">
      //       <h2>User {{ $route.params.id }}</h2>
      //       <router-view></router-view>
      //     </div>
      //   `,
      // }
    };
    return {
      treeData,
      onSelect,
      checkedKeys,
      childData
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/MasterSideNavigation.scss";
</style>