<template>
<div class="preference-main-search">
        <a-input-search
          v-model:value="value"
          placeholder="Search"
          @search="onSearch"
          class="preferences-search"
        />
      </div>
    <div class="preference-component-wrapper">
      <!-- Preference Function Group Component -->
      <div class="master-main-wrapper">
        <div class="preference-level-main">
          <table class="table">
            <thead class="thead">
              <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                <th class="th" style="width: 5%; padding: 12px 10px">
                  <a-checkbox v-model:checked="checked"></a-checkbox>
                </th>
                <th class="th" style="width: 85%; padding: 12px 10px">
                  <div class="preference-header-search">
                    <span class="th-span">Gender Master</span>
                    &nbsp;

                    <img
                      src="../../../../../assets/search.png"
                      alt=""
                      class="img-search"
                    />
                  </div>
                </th>
                <th
                  class="th"
                  style="width: 10%; padding: 0px 10px; text-align: right"
                >
                  <div class="table-header-add-main">
                    <img
                      src="../../../../../assets/addIcon.png"
                      alt=""
                      class="img-pointer"
                    />
                    <img
                      src="../../../../../assets/exportexcel.png"
                      alt=""
                      style="margin-left: 15px"
                      class="img-pointer"
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="tr" v-for="(gender, index) in genderdata" :key="index">
                <td class="td" style="text-align: left; padding: 12px 10px">
                  <a-checkbox v-model:checked="checked"></a-checkbox>
                </td>
                <td class="td" style="text-align: left; padding: 12px 10px">
                  <span> {{ gender }}</span>
                </td>
                <td class="td" style="text-align: right; padding: 12px 10px">
                  <img
                    src="../../../../../assets/deleteIcon.png"
                    alt=""
                    class="img-pointer"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="view-more-btn"
            v-if="this.count !== this.genderdata.length && this.count > 10"
          >
            <a-button
              type="none"
              shape="round"
              :size="size"
              class="borderless-btn"
              @click="viewMorePromocodes"
            >
              <template #icon>
                <PlusCircleOutlined />
                View More
              </template>
            </a-button>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { defineComponent, reactive, toRefs, watch } from "vue";
// import { PlusOutlined, FileExcelOutlined } from "@ant-design/icons-vue";
import Services from "@/services/apis";
export default defineComponent({
  components: {
    // PlusOutlined,
    // FileExcelOutlined,
    // DeleteOutlined
  },

  data() {
    return {
      countryData: [],
      count: [],
      playersListing: [],
      coachData: [],
      coachListing: [],
      organisationsListing: [],
      organisationData: [],
      corporateListing: [],
      openInvitePlayerModal: false,
      search: "",
      openFilter: false,
      selectedSport: [],
      title: "",
      paginationValues: {},
      genderdata: [],
      // currentPageNo: 1,
      checked: true,
      allBookingCount: 0,
      plainOptions: [],
      currentPageNum: 0,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getFunctionGroupDetails();
  },
  methods: {
    viewMorePromocodes() {
      this.currentPageNum++;
      this.getFunctionGroupDetails();
    },
    getFunctionGroupDetails() {
      let payload = {
        q: "",
        pageNo: 0,
        types: ["GENDER"],
      };
      Services.genderListing(payload)
        .then((response) => {
          const { data } = response;
          console.log("sth", data);
          this.count = data[0].count[0].total;

          data[0].data &&
            data[0].data.length &&
            data[0].data.map((z) => {
              this.genderdata.push(z.type == "GENDER" ? z.name : "-");
            });
          var temp = "-";
          this.genderdata = this.genderdata.filter(
            (item) => !temp.includes(item)
          );
          console.log("gender", this.genderdata);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
  },
});
</script>

<style lang="scss">
@import "../../../styles/Gender.scss";
</style>