<template>
  <div class="preference-component-wrapper">
      <!-- Preference Function Group Component -->
      <div class="master-main-wrapper">
        <div class="preference-level-main">
  <a-table
    :columns="this.columns"
    :data-source="this.tableData"
    class="site-table-custom"
    :pagination="false"
  >
    
  </a-table>
        </div>
      </div>
  </div>
</template>
<script>

import Services from "@/services/apis";
import { defineComponent } from 'vue';

const data = [];
export default defineComponent({
  setup() {
    
  },
  data(){
    return{
      tableData:[],
      columns :[],
    }
  },
  mounted() {
    this.bindDataAndColumns();
    this.getFunctionGroupDetails();
    
  },
  methods: {
    
    bindDataAndColumns(){
      this.columns =[
 {
    title: 'Site Name Master',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Abbriviation',
    dataIndex: 'abbr',
    key: 'abbr',
  },
  {
    title: 'Index',
    dataIndex: 'siteId',
    key: 'address',
  },
  {
    title: 'Description',
    key: 'desc',
    dataIndex: 'desc',

  },
] 
    },
    viewMorePromocodes() {
      this.currentPageNum++;
      this.getFunctionGroupDetails();
      console.log('current page',this.currentPageNum)
    },
    getFunctionGroupDetails() {   
      Services.sitesListing()
        .then((response) => {
          const { data } = response;
          console.log('sites--',data)
           data[0].data &&
            data[0].data.length &&
            data[0].data.map((z) => {
            this.tableData.push({ 
              name:z.name ? z.name : '-',
              abbr:z.abbr ? z.abbr : '-',
              siteId:z.siteId ? z.siteId : '-',
              desc:z.description ? z.description : '-'
            });
            console.log('table',this.tableData)
        })
          var temp = '-';
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
  },
  },
  
});
</script>
<style lang="scss">
@import "../../../styles/siteName.scss";
</style>