<template>
  <div class="master-main-wrapper">
    <MasterSideNavigation
      @masterSideNaviagtionProps="masterSideNaviagtionProps($event)"
    />
    <div class="master-right-wrapper">
      <div class="preference-main">
        <Gender
          v-if="
            navProps.includes('GLOBAL_GENDER') || navProps.includes('GLOBAL')
          "
        />
        <Title
          v-if="
            navProps.includes('GLOBAL_TITLE') || navProps.includes('GLOBAL')
          "
        />
        <CompanyType
          v-if="
            navProps.includes('GLOBAL_COMPANY_TYPES') ||
            navProps.includes('GLOBAL')
          "
        />
        <SiteName
          v-if="
            navProps.includes('GLOBAL_SITES') || navProps.includes('GLOBAL')
          "
        />
        <Currency
          v-if="
            navProps.includes('GLOBAL_CURRENCY') || navProps.includes('GLOBAL')
          "
        />
        <Country v-if="navProps.includes('LOCATION_COUNTRY')" />
        <State v-if="navProps.includes('LOCATION_STATES')" />
        <City v-if="navProps.includes('LOCATION_CITY')" />
        <Region v-if="navProps.includes('LOCATION_REGION')" />

        <Resume v-if="navProps.includes('RESUME_CANDIDATE_RESUME_TYPE')" />
        <Role v-if="navProps.includes('TEAM_ROLES')" />
        <Designation v-if="navProps.includes('TEAM_DESICNATION')" />
        <Team v-if="navProps.includes('TEAM_TEAM')" />
        <Subteams v-if="navProps.includes('TEAM_SUB_TEAM')" />

        <EduLevel
          v-if="navProps.includes('RESUME_CANDIDATE_EDUCATION_LEVEL')"
        />
        <Degree v-if="navProps.includes('RESUME_CANDIDATE_EDU_DEGREE')" />
        <EduDegree v-if="navProps.includes('RESUME_CANDIDATE_MAIN')" />
        <EduSpecialization
          v-if="navProps.includes('RESUME_CANDIDATE_EDU_SPECIALIZATION')"
        />
        <EduInstitute
          v-if="navProps.includes('RESUME_CANDIDATE_EDU_INSTITUTES')"
        />
        <MasterCompanyMeetingDocuments
          v-if="
            navProps.includes('COMPANY_MAIN') ||
            navProps.includes('MEETINGS_MAIN')
          "
        />
        <Meetings
          v-if="
            navProps.includes('COMPANY_MAIN') ||
            navProps.includes('MEETINGS_MAIN')
          "
        />
        <Documents
          v-if="
            navProps.includes('COMPANY_MAIN') ||
            navProps.includes('DOCUMENTS_MAIN')
          "
        />
        <Preferences v-if="navProps.includes('PREFERENCES_MAIN')" />
        <Vacancy
          v-if="
            navProps.includes('VACANCY_MAIN') ||
            navProps.includes('VACANCY_STATUS')
          "
        />
      </div>
      <div style="background-color: white; padding: 20px">
        <User v-if="navProps.includes('USER')" />
      </div>
    </div>
  </div>
</template>

<script>
import MasterSideNavigation from "@/features/master/components/masterSideNavigation.vue";
import Gender from "@/features/master/components/masterFeature/global/gender.vue";
// import Teams from "../components/masterFeature/teams/index.vue";
import Resume from "../components/masterFeature/resumeCandidate/resumeType.vue";
import Degree from "../components/masterFeature/resumeCandidate/eduDegree.vue";
import EduLevel from "../components/masterFeature/resumeCandidate/eduLevel.vue";
import EduSpecialization from "../components/masterFeature/resumeCandidate/eduSpecialization.vue";
import EduInstitute from "../components/masterFeature/resumeCandidate/eduInstitute.vue";
import MasterCompanyMeetingDocuments from "../components/masterFeature/companyMeetingDocuments/index.vue";
import Title from "@/features/master/components/masterFeature/global/title.vue";
import CompanyType from "@/features/master/components/masterFeature/global/companyType.vue";
import SiteName from "@/features/master/components/masterFeature/global/siteName.vue";
import Currency from "@/features/master/components/masterFeature/global/currency.vue";
import Country from "@/features/master/components/masterFeature/location/country.vue";
import State from "@/features/master/components/masterFeature/location/states.vue";
import City from "@/features/master/components/masterFeature/location/city.vue";
import Region from "@/features/master/components/masterFeature/location/region.vue";
import Role from "@/features/master/components/masterFeature/teams/role.vue";
import Designation from "./masterFeature/teams/designation.vue";
import Team from "@/features/master/components/masterFeature/teams/teams.vue";
// import Designation from "@/features/master/components/masterFeature/teams/designation.vue";
import Subteams from "@/features/master/components/masterFeature/teams/subteam.vue";
import Vacancy from "@/features/master/components/masterFeature/vacancy/index.vue";
import User from "@/features/users/components/teamListing.vue";
import Preferences from "./masterFeature/preferences/index.vue";
import Documents from "./masterFeature/companyMeetingDocuments/document.vue";
import Meetings from "./masterFeature/companyMeetingDocuments/meetings.vue";

export default {
  name: "Master",
  components: {
    MasterSideNavigation,
    Gender,
    Team,
    Resume,
    // MasterCompanyMeetingDocuments,
    Title,
    CompanyType,
    SiteName,
    Currency,
    Country,
    State,
    Region,
    City,
    Preferences,
    Vacancy,
    User,
    Role,
    Subteams,
    Designation,
    EduLevel,
    EduSpecialization,
    EduInstitute,
    Degree,
    MasterCompanyMeetingDocuments,
    Documents,
    Meetings,
  },
  data() {
    return {
      navProps: "GLOBAL",
    };
  },
  methods: {
    masterSideNaviagtionProps(newValue) {
      this.navProps = newValue.checkedNodes.map((x) => x.key);
      this.navProps.toString();
      let prevOption = this.navProps[0].split("_")[0];
      let newOption = "";
      this.navProps.filter((val) => {
        if (newOption.length === 0 && !val.includes(prevOption)) {
          newOption = val;
        }
      });

      let copyValues = [];

      if (newOption) {
        this.navProps.map((val) => {
          if (!val.includes(prevOption)) {
            copyValues.push(val);
          }
          // console.log('copyValues :>> ', copyValues);
        });

        this.navProps = copyValues;
        console.log("newValue", this.navProps);
        this.$forceUpdate();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/MasterMain.scss";
</style>

