<template>
  <div class="vacancy-main">
    <!-- Preference search -->
    <div class="vacancy-main-search">
         <a-input-search
            v-model:value="value"
            placeholder="Search"
            @search="onSearch"
            class="vacancy-search"
        />
    </div>
    <div class="vacancy-component-wrapper">
            <!-- Vacancy Stage Component -->
            <div class="vacancy-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                            <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                            <th class="th" style="width:85%;padding:12px 10px">
                                <div class="vacancy-header-search">
                                 <span class="th-span">Stage Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:10%;padding:12px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="statge in statgeData" :key="statge.id"> 
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:12px 10px">
                                   {{statge.name}}
                                </td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>

              <!-- Vacancy Type Component -->

              <div class="vacancy-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                             <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                            <th class="th" style="width:85%;padding:12px 10px">
                                <div class="vacancy-header-search">
                                 <span class="th-span">Vacancy Type Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:10%;padding:12px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="vacancyType in vacancyTypeData" :key="vacancyType.id"> 
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:12px 10px">
                                  {{vacancyType.name}}
                                </td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>
            

             <!-- Vacancy Status Component -->
            <div class="vacancy-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                             <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                            <th class="th" style="width:85%;padding:12px 10px">
                                <div class="vacancy-header-search">
                                 <span class="th-span">Vacancy Status Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:10%;padding:12px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="vacancyStatus in vacancyStatusData" :key="vacancyStatus.id"> 
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 {{vacancyStatus.name}}
                                </td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>

            <!-- Vacancy Department Component -->
            <div class="vacancy-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                             <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                            <th class="th" style="width:85%;padding:12px 10px">
                                <div class="vacancy-header-search">
                                 <span class="th-span">Department Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:10%;padding:12px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="department in departmentData" :key="department.id"> 
                               <td class="td" style="text-align:left;padding:12px 10px">
                                </td>
                                <td class="td" style="text-align:left;padding:12px 10px">
                                {{department.name}}
                                </td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>

             <!-- Vacancy Procedure Type  Component -->
            <div class="vacancy-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                             <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                            <th class="th" style="width:20%;padding:12px 10px">
                                <div class="vacancy-header-search">
                                 <span class="th-span">Procedure Type Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:20%;padding:12px 10px">
                                <div class="vacancy-header-search">
                                 <span class="th-span">ABBR</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:25%;padding:12px 10px">
                                <div class="vacancy-header-search">
                                 <span class="th-span">Priority</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:20%;text-align:center">
                                This Mile Stone
                            </th>
                            <th class="th" style="width:10%;padding:12px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="procedureType in procedureTypeData" :key="procedureType.id"> 
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:12px 10px">
                                {{procedureType.name}}
                                </td>
                                <td class="td" style="text-align:left;padding:12px 10px">
                                {{procedureType.abbrevation}}
                                </td>
                                <td class="td" style="text-align:left;padding:12px 10px">
                                {{procedureType.priority}}
                                </td>
                                <td class="td"></td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>

             <!-- Vacancy Procedure Group  Component -->
            <div class="vacancy-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                             <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                            <th class="th" style="width:85%;padding:12px 10px">
                                <div class="vacancy-header-search">
                                 <span class="th-span">Procedure Group Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:10%;padding:12px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="procedureGroup in procedureGroupMaster" :key="procedureGroup.id"> 
                               <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:12px 10px">
                                {{procedureGroup.name}}
                                </td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>

              <!-- Vacancy Interview Type Component -->
            <div class="vacancy-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                             <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                            <th class="th" style="width:85%;padding:12px 10px">
                                <div class="vacancy-header-search">
                                 <span class="th-span">Interview Type Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:10%;padding:12px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="interviewType in interviewTypeMaster" :key="interviewType.id"> 
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 {{interviewType.name}}
                                </td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>

             <!-- Vacancy Tracker Type Component -->
            <div class="vacancy-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                             <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                            <th class="th" style="width:85%;padding:12px 10px">
                                <div class="vacancy-header-search">
                                 <span class="th-span">Tracker Type Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:10%;padding:12px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="trackerTyp in trackerTypeMaster" :key="trackerTyp.id"> 
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 {{trackerTyp.name}}
                                </td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>

             <!-- Vacancy Reminder Type Component -->
            <div class="vacancy-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                            <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                            <th class="th" style="width:85%;padding:12px 10px">
                                <div class="vacancy-header-search">
                                 <span class="th-span">Reminder Type Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:10%;padding:12px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="reminderType in reminderTypeMaster" :key="reminderType.id"> 
                               <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 {{reminderType.name}}
                                </td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>


             <!-- Vacancy Rm Status Component -->
            <div class="vacancy-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                             <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                            <th class="th" style="width:20%;padding:12px 10px">
                                <div class="vacancy-header-search">
                                 <span class="th-span">RM Status Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:40%;padding:12px 10px">
                             Description
                            </th>
                            <th class="th" style="width:10%;padding:12px 10px;text-align:center">
                             Colour
                            </th>
                            <th class="th" style="width:10%;padding:12px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="rmStatus in rmStatusMaster" :key="rmStatus.id"> 
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 {{rmStatus.name}}
                                </td>
                                <td class="td" style="text-align:left;padding:12px 10px">
                                </td>
                                <td class="td" style="text-align:left;padding:12px 10px;text-align:center">
                                 <div style="display:flex;justify-content:center;width:100%">
                                 <div :style="{
                                     width:'16px',
                                     height:'16px',
                                     background:`${rmStatus.color}`
                                     }">
                                 </div>
                                 </div>
                                </td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>

             <!-- Vacancy Sector Component -->
            <div class="vacancy-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                             <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                            <th class="th" style="width:85%;padding:12px 10px">
                                <div class="vacancy-header-search">
                                 <span class="th-span">Sector Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:10%;padding:12px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="sectorMaster in sectorMasterData" :key="sectorMaster.id"> 
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:12px 10px">
                                 {{sectorMaster.name}}
                                </td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>


             <!-- Vacancy Industry Component -->
            <div class="vacancy-level-main">
                    <table class="table">
                    <thead class="thead">
                        <tr class="tr" style="background: rgba(255, 235, 174, 0.3)">
                             <th class="th" style="width:5%;padding:12px 10px">
                                <a-checkbox v-model:checked="checked" ></a-checkbox>
                            </th>
                            <th class="th" style="width:30%;padding:12px 10px">
                                <div class="vacancy-header-search">
                                 <span class="th-span">Industry Master</span>
                                  <img src="../../../../../assets/search.png"  alt="" class="img-search"/>
                                </div>
                            </th>
                            <th class="th" style="width:55%;text-align:left">
                              <div class="vacancy-header-search">
                                 <span class="th-span">Sector</span>
                                  <img src="../../../../../assets/filtericon.png"  alt="" class="img-filter"/>
                                </div>
                            </th>
                            <th class="th" style="width:10%;padding:12px 10px;text-align:right">
                                 <div class="table-header-add-main" >
                                  <img src="../../../../../assets/addIcon.png"  alt="" class="img-pointer"/>
                                  <img src="../../../../../assets/exportexcel.png" alt="" style="margin-left:15px" class="img-pointer"/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                           <tr class="tr" v-for="industryMaster in industryMasterData" :key="industryMaster.id"> 
                                 <td class="td" style="text-align:left;padding:12px 10px">
                                 <a-checkbox v-model:checked="checked" ></a-checkbox>
                                </td>
                                <td class="td" style="text-align:left;padding:12px 10px">
                                  {{industryMaster.name}}
                                </td>
                                 <td class="td" style="text-align:left;padding:12px 10px 12px 0px">
                                  {{industryMaster.name}}
                                </td>
                                 <td class="td" style="text-align:right;padding:12px 10px">
                                 <img src="../../../../../assets/deleteIcon.png"  alt="" class="img-pointer"/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>


    </div>
  </div>
</template>

<script>
import Services from "@/services/apis";
export default {
    mounted() {
    window.scrollTo(0, 0);
    this.getStageDetails();
    this.getVacancyTypeDetails();
    this.getVacancyStatusDetails();
    this.getDepartmentsDetails();
    this.getProcedureTypeDetails();
    this.getProcedureGroupDetails();
    this.getInterViewTypeDetails();
    this.getTrackerTypeDetails();
    this.getReminderTypeDetails();
    this.getRmStatusDetails();
    this.getSectorDetails();
    this.getIndustryDetails();
  },  
  methods:{
    getStageDetails(){
       let payload = {
        q: "",
        pageNo: 0,
        mode: "LISTING",
        types: ['VACANCY']
      };
      Services.getStageDetail(payload)
        .then((response) => {
          const { data } = response;
          const {data: responsesData = []} =  data;
          this.statgeData= responsesData;
          console.log('stages',response)
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    getVacancyTypeDetails() {
      let payload = {
        q: "",
        pageNo: 0,
        mode: "LISTING",
        types: ['VACANCY_TYPES']
      };
      
   
      Services.getVacancyTypeDetail(payload)
        .then((response) => {
          const { data } = response;
          const {data: responsesData = []} =  data[0];
          this.vacancyTypeData= responsesData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    getVacancyStatusDetails() {
     
      Services.getVacancyStatusDetail()
        .then((response) => {
          const { data } = response;
          const {data: responsesData = []} =  data;
          this.vacancyStatusData= responsesData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    getDepartmentsDetails() {
       
      Services.getDepartmentsDetail()
        .then((response) => {
          const { data } = response;
          const {data: responsesData = []} =  data[0];
          this.departmentData= responsesData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    getProcedureTypeDetails() {
         let payload = {
        q: "",
        pageNo: 0,
        mode: "LISTING",
        types: ['PROCEDURE']
      };
      
      Services.getProcedureTypeDetail(payload)
        .then((response) => {
          const { data } = response;
          const {data: responsesData = []} =  data;
          this.procedureTypeData= responsesData;
          console.log('proced',data)
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    getProcedureGroupDetails() {
        let payload = {
        q: "",
        pageNo: 0,
        mode: "LISTING",
        types: ['PROCEDURE_GROUPS']
      };
      Services.getProcedureGroupDetail(payload)
        .then((response) => {
          const { data } = response;
          const {data: responsesData = []} =  data[0];
          this.procedureGroupMaster= responsesData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    getInterViewTypeDetails() {
       let payload = {
        q: "",
        pageNo: 0,
        mode: "LISTING",
        types: ['INTERVIEW_TYPES']
      };
      Services.getInterViewTypeDetail(payload)
        .then((response) => {
          const { data } = response;
          const {data: responsesData = []} =  data[0];
          this.interviewTypeMaster= responsesData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    getTrackerTypeDetails() {
       
      Services.getTrackerTypeDetail()
        .then((response) => {
          const { data } = response;
          const {data: responsesData = []} =  data[0];
          this.trackerTypeMaster= responsesData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    getReminderTypeDetails() {
       
      Services.getReminderTypeDetail()
        .then((response) => {
          const { data } = response;
          const {data: responsesData = []} =  data[0];
          this.reminderTypeMaster= responsesData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    getRmStatusDetails() {
     
      Services.getRmStatusDetail()
        .then((response) => {
          const { data } = response;
          const {data: responsesData = []} =  data[0];
          this.rmStatusMaster= responsesData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    getSectorDetails () {
       
      Services.getSectorDetail()
        .then((response) => {
          const { data } = response;
          const {data: responsesData = []} =  data;
          this.sectorMasterData= responsesData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
    getIndustryDetails () {
      
      Services.getIndustryDetail()
        .then((response) => {
          const { data } = response;
          const {data: responsesData = []} =  data;
          this.industryMasterData= responsesData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
  },  
  data() {
    return {
        statgeData: [],
        vacancyTypeData: [],
        vacancyStatusData: [],
        departmentData: [],
        procedureTypeData: [],
        procedureGroupMaster: [],
        interviewTypeMaster: [],
        trackerTypeMaster: [],
        reminderTypeMaster: [],
        rmStatusMaster: [],
        sectorMasterData: [],
        industryMasterData: [],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/Vacancy.scss";
</style>

